import React from 'react'
import { Link, graphql } from 'gatsby'
import '../styles/logo-slider.css'
import ReactMarkdown from 'react-markdown'
import WistiaThumbnail from './wistia-thumbnail'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { checkSvg } from '../helper-functions'

export default function LogoSlider({ layoutPiece }) {
  const ClientLogos = layoutPiece?.carouselCards
  return (
    <div className="logo-slider standard-component-spacing">
      <div className="content-cap">
        <div className="text-header">
          <ReactMarkdown components={{p: 'h2'}}>{layoutPiece?.logoCarouselHeadline?.headlineText}</ReactMarkdown>
          {layoutPiece.bodyText ?
            <ReactMarkdown className="p-mobile-margin markdown">{layoutPiece.bodyText.bodyText}</ReactMarkdown>
            : ""
          }
        </div>
        <div className={ClientLogos.length == 1 ? "logo-carousel-single" : ClientLogos.length == 2 ? "logo-carousel-split" : "logo-carousel"}>
          {ClientLogos?.map((clientLogo, index) => {
            const ClientInfos = clientLogo?.clientInfo
            const gatsbyImage = getImage(clientLogo?.clientLogo?.image)
            const image = clientLogo?.clientLogo?.image
            return(
              <div key={clientLogo?.id} className="logo-carousel-item">
                {
                  clientLogo.wistiaVideoId ?
                    <WistiaThumbnail videoId={clientLogo.wistiaVideoId} />
                    :
                    clientLogo.linkToPageSlug ?
                      <Link to={clientLogo.linkToPageSlug} title={image?.title}>
                        {
                          checkSvg(image?.url) ?
                            <img src={image?.url} alt={image?.description} className="logo-carousel-image" />
                          :
                          <GatsbyImage image={gatsbyImage} alt={image?.description} className="logo-carousel-image" />
                        }
                      </Link>
                      :
                      clientLogo.linkToPageUrl ?
                        <a href={clientLogo.linkToPageUrl} title={image?.title}>
                          {
                            checkSvg(image?.url) ?
                              <img src={image?.url} alt={image?.description} className="logo-carousel-image" />
                              :
                              <GatsbyImage image={gatsbyImage} alt={image?.description} className="logo-carousel-image" />
                          }
                        </a>
                        :
                        checkSvg(image?.url) ?
                          <img src={image?.description} alt={image?.description} className="logo-carousel-image" />
                          :
                          <GatsbyImage image={gatsbyImage} alt={image?.description} className="logo-carousel-image" />
                }
                {
                  clientLogo.wistiaVideoId ?
                    <div className="logo-carousel-desc">
                      {ClientInfos?.map((clientInfo, index) => {
                        return (
                          <>
                            <span className="sub-text no-pad-header" key={index}>{clientInfo}</span><br/>
                          </>
                        )
                      })}
                    </div> :
                  ""
                }

              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment ClientFeature on ContentfulPortfolioCarousel {
    id
    bodyText {
      bodyText
    }
    logoCarouselHeadline: headlineText {
      headlineText
    }
    carouselCards {
      id
      clientInfo
      clientLogo {
        image {
          gatsbyImageData(width: 500, placeholder: BLURRED)
          url
          title
          description
        }
      }
      wistiaVideoId
      linkToPageSlug
      linkToPageUrl
    }
  }
`
