import * as React from "react"
import { Suspense } from "react"
//import component list
import LandingBanner from '../components/landing-banner'
import ClientFeatureSlider from '../components/client-feature'
import LoadingSpinner from '../components/_loading-spinner'

const FourBucket = React.lazy(() => import('../components/four-bucket'))
const GlobalDuplexBanner = React.lazy(() => import('../components/global-duplex-banner'))
const FeatureList = React.lazy(() => import('../components/feature-list'))
const BlogFeature = React.lazy(() => import('../components/blog-feature'))
const QuoteFeature = React.lazy(() => import('../components/quote-feature'))
const ContainedBanner = React.lazy(() => import('../components/contained-banner'))

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces?.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulLandingBanner' && <LandingBanner layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulPortfolioCarousel' && <ClientFeatureSlider layoutPiece={layoutPiece} key={index}/>,

          layoutPiece.internal.type === 'ContentfulSuiteCardsComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FourBucket layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><GlobalDuplexBanner layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulAttributeListComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FeatureList layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulBlogFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><BlogFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulQuoteDuplex' && <Suspense key={index} fallback={<LoadingSpinner />}><QuoteFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulJoinTheTeamFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><ContainedBanner layoutPiece={layoutPiece} /></Suspense>,
        ])
      })
    }
}

export default ContentArea
