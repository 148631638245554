import React from 'react'
import { Link, graphql } from 'gatsby'
import '../styles/landing-banner.css'
import ReactMarkdown from 'react-markdown'
import WistiaLink from './wistia-link'
import LinkArrowLight from '../assets/link-arrow-light.svg'
import LinkArrow from '../assets/link-arrow.svg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { checkSvg } from '../helper-functions'

export default function LandingBanner({ layoutPiece }) {
    const mainImageGatsby = getImage(layoutPiece?.image?.image)
    const mainImage = layoutPiece?.image?.image
    const badge1ImageGatsby = getImage(layoutPiece?.badge1?.image)
    const badge1Image = layoutPiece?.badge1?.image
    const badge2ImageGatsby = getImage(layoutPiece?.badge2?.image)
    const badge2Image = layoutPiece?.badge2?.image
    const badge3ImageGatsby = getImage(layoutPiece?.badge3?.image)
    const badge3Image = layoutPiece?.badge3?.image
    const mediaLinkButton = layoutPiece?.mediaLinkButton 
    const button = layoutPiece?.button 
    const link = layoutPiece?.link 

    function GetBackgroundColor() {
        switch (layoutPiece?.backgroundColor) {
          case 'Black':
            return "landing-hero dark-background background-hero-spacing"
          case 'Grey':
            return "landing-hero grey-background background-hero-spacing"
          case 'Off-white':
            return "landing-hero eggshell-background background-hero-spacing"
          default:
            return "landing-hero light-background background-hero-spacing"
        }
      }

    return (
        <div className={GetBackgroundColor()}>
            <div className='content-cap'>
                {/* Desktop Layout: hide on mobile */}
                <div className='desktop-layout-nov2023'>
                    <div className='hero-left'>
                        <h1>{layoutPiece.headlineText}</h1>
                        <ReactMarkdown className='markdown'>{layoutPiece.bodyText.bodyText}</ReactMarkdown>
                        <div className='button-holder'>
                            {
                                button && button?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                                    <a className='button-default solid-light-button' href={button?.fullUrl} title={button?.title}>{button?.buttonText}</a>
                                :
                                button && button?.fullUrl ?
                                    <a className='button-default solid-dark-button' href={button?.fullUrl} title={button?.title}>{button?.buttonText}</a>
                                : ''
                            }
                            {
                                button && button?.slug && layoutPiece?.backgroundColor === 'Black' ?
                                    <Link className='button-default solid-light-button' to={button?.slug} title={button?.title}>{button?.buttonText}</Link>
                                :
                                button && button?.slug ?
                                    <Link className='button-default solid-dark-button' to={button?.slug} title={button?.title}>{button?.buttonText}</Link>
                                : ''
                            }
                            {
                                link && link?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                                    <a className='cta-link-default light-cta-link banner-link' href={link?.fullUrl} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrowLight /></span></a>
                                :
                                link && link?.fullUrl ?
                                    <a className='cta-link-default dark-cta-link banner-link' href={link?.fullUrl} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrow /></span></a>
                                : ''
                            }
                            {
                                link?.slug && layoutPiece?.backgroundColor === 'Black' ?
                                    <Link className='cta-link-default light-cta-link banner-link' to={link?.slug} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrowLight /></span></Link>
                                :
                                link && link?.slug ?
                                    <Link className='cta-link-default dark-cta-link banner-link' to={link?.slug} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrow /></span></Link>
                                : ''
                            }
                        </div>
                        <div className='awards-container'>
                            <div className='badge-1-container'>
                                {
                                    layoutPiece?.badge1 ?
                                        checkSvg(badge1Image?.url) ?
                                            <img src={badge1Image?.url} alt={badge1Image?.description} className='badge-1' />
                                            :
                                            <GatsbyImage image={badge1ImageGatsby} alt={badge1Image?.description} className='badge-1' />
                                        : ''
                                }
                            </div>
                            <div className='badge-2-container'>
                                {
                                    layoutPiece?.badge2 ?
                                        checkSvg(badge2Image?.url) ?
                                            <img src={badge2Image?.url} alt={badge2Image?.description} className='badge-2' />
                                            :
                                            <GatsbyImage image={badge2ImageGatsby} alt={badge2Image?.description} className='badge-2' />
                                        : ''
                                }
                            </div>
                            <div className='badge-3-container'>
                                {
                                    layoutPiece?.badge3 ?
                                        checkSvg(badge3Image?.url) ?
                                            <img src={badge3Image?.url} alt={badge3Image?.description} className='badge-3' />
                                            :
                                            <GatsbyImage image={badge3ImageGatsby} alt={badge3Image?.description} className='badge-3' />
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className='hero-right'>
                        <div className='main-image-container'>
                            {
                                layoutPiece?.image ?
                                    checkSvg(mainImage?.url) ?
                                        <img src={mainImage?.url} alt={mainImage?.description} className='main-image-file' />
                                        :
                                        <GatsbyImage image={mainImageGatsby} alt={mainImage?.description} className='main-image-file' />
                                    : ''
                            }
                        </div>
                        <div className='video-button-feature'>
                            {
                                mediaLinkButton?.wistiaVideoId ?
                                    <WistiaLink videoId={mediaLinkButton?.wistiaVideoId} text={mediaLinkButton?.mediaLinkCaption} textColor={'white'} black={layoutPiece.backgroundColor === 'Black' ? true : false} />
                                : ''
                            }
                        </div>
                    </div>
                </div>
                {/* End of Desktop Layout */}
                {/* Mobile Layout: hide on desktop */}
                <div className='mobile-layout-nov2023'>
                    <div className='awards-container'>
                        <div className='badge-1-container'>
                            {
                                layoutPiece?.badge1 ?
                                    checkSvg(badge1Image?.url) ?
                                        <img src={badge1Image?.url} alt={badge1Image?.description} className='badge-1' />
                                        :
                                        <GatsbyImage image={badge1ImageGatsby} alt={badge1Image?.description} className='badge-1' />
                                    : ''
                            }
                        </div>
                        <div className='badge-2-container'>
                            {
                                layoutPiece?.badge2 ?
                                    checkSvg(badge2Image?.url) ?
                                        <img src={badge2Image?.url} alt={badge2Image?.description} className='badge-2' />
                                        :
                                        <GatsbyImage image={badge2ImageGatsby} alt={badge2Image?.description} className='badge-2' />
                                    : ''
                            }
                        </div>
                        <div className='badge-3-container'>
                            {
                                layoutPiece?.badge3 ?
                                    checkSvg(badge3Image?.url) ?
                                        <img src={badge3Image?.url} alt={badge3Image?.description} className='badge-3' />
                                        :
                                        <GatsbyImage image={badge3ImageGatsby} alt={badge3Image?.description} className='badge-3' />
                                    : ''
                            }
                        </div>
                    </div>  
                    <h1>{layoutPiece.headlineText}</h1>
                    <ReactMarkdown className='markdown'>{layoutPiece.bodyText.bodyText}</ReactMarkdown>    
                    <div className='main-image-container'>
                        {
                            layoutPiece?.image ?
                                checkSvg(mainImage?.url) ?
                                    <img src={mainImage?.url} alt={mainImage?.description} className='main-image-file' />
                                    :
                                    <GatsbyImage image={mainImageGatsby} alt={mainImage?.description} className='main-image-file' />
                                : ''
                        }
                    </div>
                    <div className='button-holder'>
                        {
                            button && button?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                                <a className='button-default solid-light-button' href={button?.fullUrl} title={button?.title}>{button?.buttonText}</a>
                            :
                            button && button?.fullUrl ?
                                <a className='button-default solid-dark-button' href={button?.fullUrl} title={button?.title}>{button?.buttonText}</a>
                            : ''
                        }
                        {
                            button && button?.slug && layoutPiece?.backgroundColor === 'Black' ?
                                <Link className='button-default solid-light-button' to={button?.slug} title={button?.title}>{button?.buttonText}</Link>
                            :
                            button && button?.slug ?
                                <Link className='button-default solid-dark-button' to={button?.slug} title={button?.title}>{button?.buttonText}</Link>
                            : ''
                        }
                        {
                            link && link?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                                <a className='cta-link-default light-cta-link banner-link' href={link?.fullUrl} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrowLight /></span></a>
                            :
                            link && link?.fullUrl ?
                                <a className='cta-link-default dark-cta-link banner-link' href={link?.fullUrl} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrow /></span></a>
                            : ''
                        }
                        {
                            link?.slug && layoutPiece?.backgroundColor === 'Black' ?
                                <Link className='cta-link-default light-cta-link banner-link' to={link?.slug} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrowLight /></span></Link>
                            :
                            link && link?.slug ?
                                <Link className='cta-link-default dark-cta-link banner-link' to={link?.slug} title={link?.title}>{link?.text}<span className='link-arrow-icon'><LinkArrow /></span></Link>
                            : ''
                        }
                    </div>
                    <div className='video-button-feature'>
                        {
                            mediaLinkButton?.wistiaVideoId ?
                                <WistiaLink videoId={mediaLinkButton?.wistiaVideoId} text={mediaLinkButton?.mediaLinkCaption} textColor={'white'} black={layoutPiece.backgroundColor === 'Black' ? true : false} />
                            : ''
                        }
                    </div>    
                </div>
                {/* End of Mobile Layout */}
            </div>
        </div>
    )
}

export const query = graphql`
  fragment LandingBanner on ContentfulLandingBanner {
    id
    backgroundColor
    bodyText {
      bodyText
    }
    headlineText
    mediaLinkButton {
      mediaLinkCaption
      mediaLinkUrl
      wistiaVideoId
    }
    button {
      buttonText
      buttonOutlined
      fullUrl
      slug
      title
    }
    link {
      text
      fullUrl
      slug
      title
    }
    badge1 {
        image {
            gatsbyImageData(width: 150, placeholder: BLURRED)
            url
            title
            description
          }
    }
    badge2 {
        image {
            gatsbyImageData(width: 150, placeholder: BLURRED)
            url
            title
            description
          }
    }
    badge3 {
        image {
            gatsbyImageData(width: 150, placeholder: BLURRED)
            url
            title
            description
          }
    }
    image {
      image {
        gatsbyImageData(width: 900, placeholder: BLURRED)
        url
        title
        description
      }
    }
  }
`