import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

import LandingContentArea from '../templates/landing-content-area'

export default function ProductPage({ data, pageContext }) {
  const layoutPieces = data.contentfulPage.pageComponents
  return (
    <Layout header={data?.contentfulPage?.header} footer={data?.contentfulPage?.footer}>
      <LandingContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <title>{data?.contentfulPage?.metaTitle}</title>
    <meta name="description" content={data?.contentfulPage?.metaDescription} />
    <meta property="og:title" content={data?.contentfulPage?.ogMetaTitle ? data.contentfulPage.ogMetaTitle : data?.contentfulPage?.metaTitle} />
    <meta property="og:description" content={data?.contentfulPage?.ogMetaDescription ? data.contentfulPage.ogMetaDescription : data?.contentfulPage?.metaDescription} />
    <meta property="og:type" content={data?.contentfulPage?.ogMetaType} />
    <meta property="og:url" content="https://www.entrata.com" />
    <meta property="og:image" content={data?.contentfulPage?.ogMetaImage?.image ? data?.contentfulPage?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query HomePageContent {
    contentfulPage {
      header
      footer
      metaDescription
      metaTitle
      ogMetaTitle
      ogMetaDescription
      ogMetaType
      ogMetaImage {
        image {
          url
        }
      }
      # Get the list of components to load into the page
      pageComponents {
        ... on Node {
          internal {
            type
          }
        }
        ... on ContentfulLandingBanner {
          ...LandingBanner
        }
        ... on ContentfulPortfolioCarousel {
          ...ClientFeature
        }
        ... on ContentfulSuiteCardsComponent {
          ...SuiteCards
        }
        ... on ContentfulDuplexFeature {
          ...DuplexFeature
        }
        ... on ContentfulAttributeListComponent {
          ...AttributeListComponent
        }
        ... on ContentfulJoinTheTeamFeature {
          ...JoinTheTeamFeature
        }
      }
    }
  }
`

/*

---------- Contentful Blog Feature Query ----------

... on ContentfulBlogFeature {
  ...BlogFeature
}

---------- Contentful Quote Duplex Query ----------

... on ContentfulQuoteDuplex {
  ...QuoteDuplex
}

*/
